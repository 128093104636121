import {ResponseError, Shop, Translation, Translations} from '@/types';

//returns id of format 'aaaaaaaa'-'aaaa'-'aaaa'-'aaaa'-'aaaaaaaaaaaa'
export const guid = () => {
    const s4 = () => {
        return Math.floor((1 + Math.random()) * 0x10000)
            .toString(16)
            .substring(1);
    }
 
    return s4() + s4() + '-' + s4() + '-' + s4() + '-' + s4() + '-' + s4() + s4() + s4();
}

//Ex: statusCode: 500, prefix: "error." => "error.server"; 
export const errorToi18nKey = (statusCode: number, prefix = "error.") : string => {

    let message = 'unknown';

    if (statusCode >= 500) {
        message = 'server';
    } else if(statusCode === 404) {
        message = 'notFound';
    } else if (statusCode >= 400) {
        message = 'badRequest';
    }
    else if (statusCode >= 300) {
        message = 'moved';
    }

    return `${prefix}${message}`;
}

export const toKebabCase = (str: string) =>
  str
    .match(/[A-Z]{2,}(?=[A-Z][a-z]+[0-9]*|\b)|[A-Z]?[a-z]+[0-9]*|[A-Z]|[0-9]+/g)
    ?.map((x) => x.toLowerCase())
    .join("-");


export function isResponseError (obj: any): obj is ResponseError {
    return 'type' in obj && 'title' in obj && 'status' in obj;
}

export function isOpen(shop:Shop):boolean {
    if(shop.externalAppUri)
        return true;
    
    return shop.openForBusiness;
    //
    // const weekday = ["Sunday","Monday","Tuesday","Wednesday","Thursday","Friday","Saturday"];
    // const now = new Date();
    //
    // const todayOpeningHours = shop.openingHours.find(o=>o.dayOfWeek == weekday[now.getDay()].toLowerCase());
    //
    // if(shop.status === 'offline' || shop.status === 'paused' || todayOpeningHours && todayOpeningHours.closed || !todayOpeningHours)
    //     return false;
    //
    // if(!todayOpeningHours.opens || !todayOpeningHours.closes)
    //     return false;
    //
    // const opens =new Date(todayOpeningHours.opens);
    // opens.setFullYear(now.getFullYear(),now.getMonth(),now.getDate())
    //
    // const closes =new Date(todayOpeningHours.closes);
    // closes.setFullYear(now.getFullYear(),now.getMonth(),now.getDate())
    //
    // return now > opens && now < closes;
    // const weekDayOpeningHours = shop.openingHours.find(x=>x.dayOfWeek == date.toLocaleString('en-us', {  weekday: 'long' }));
    // if(!weekDayOpeningHours || weekDayOpeningHours.closed || shop.status == "offline"){
    //     return false;
    // }
    //
    // const opens = new Date(Date.parse(weekDayOpeningHours.opens));
    // const closes = new Date(Date.parse(weekDayOpeningHours.closes));
    //
    // return date > opens && date < closes;
}

export function i18nTranslate(i18n:Translations, property:string, locale:string, defaultValue?:string):string {
    if(!i18n)
        return defaultValue || "";
    const translations = i18n[property] as Translation[];
    if(!translations)
        return defaultValue || "";
    const text = translations.find(t => t.language === locale)?.text;

    return text || defaultValue || "";
}

export function getI18nImageId(i18n:Translations, locale:string):string|null {
    if(!i18n)
        return null;
    const translations = i18n["imageId"] as Translation[];
    if(!translations)
        return null;
    return translations.find(t => t.language === locale)?.text ?? null;
}

export function isReachable(url:string):Promise<boolean | void>{
    return fetch(url, { method: 'HEAD', mode: 'no-cors' })
        .then(function(resp) {
            return resp && (resp.ok || resp.type === 'opaque');
        })
        .catch(function(err) {
            console.warn('[conn test failure]:', err);
        });
}