import {createI18n} from 'vue-i18n';
import axios from "axios";

const messages = {
    en: {
        home: {
            header: 'Welcome!',
            subHeader: 'Choose your restaurant',
        },
        list: {
            menu: 'Menu'
        },
        shopchanged: {
            header: 'Warning!',
            text: 'You have a pending order linked to a different restaurant than the current one.'
        },
        checkout: {
            header: 'Checkout',
            pickup: 'Pickup time',
            orderHeader: 'My order',
        },
        payment: {
            header: 'Pay order'
        },
        termsAndConditions: {
            header: 'Terms and Conditions'
        },
        suggestedArticles: {
            header: 'May we offer something else?',
        },
        selectedShop: {
            header: 'Choose where to eat',
            extraInfo: ''
        },
        search: {
            label: 'Search restaurant',
            placeholder: 'Find restaurant',
            noMatch: 'No matching restaurants'
        },
        shopSelector: {
            singleShop: {
                start: "Start ordering",
                closed: "Closed for today",
                paused: "Closed for ordering",
                openingHoursHeader: "Opening hours",
            }
        },
        'show-password': {
            show: 'Show password',
            hide: 'Hide password'
        },
        button: {
            showMore: 'Show more',
            showMoreRestaurants: 'Show more restaurants',
            open: 'Open',
            closed: 'Closed',
            paused: 'Weborders closed',
            dineInPlace: 'At the restaurant',
            takeAway: 'Take away',
            pay: 'Pay',
            order: 'Order',
            change: 'Change',
            noThanks: 'No thanks',
            done: 'Done',
            cancel: 'Cancel',
            changeRestaurant: 'Change restaurant',
            changeLanguage: 'Change language',
            cancelOrder: 'Cancel order',
            yes: 'Yes',
            no: 'No',
            add: 'Add',
            backToCategory: 'Back to {category}',
            backToCategories: 'Back to categories',
            backToHome: 'Back to {category}',
            backToArticle: 'Back to {article}',
            backToShopPage: 'Back',
            back: 'Back',
            receipt: 'Receipt'

        },
        item: {
            notAvalible: 'item out of stock',
            favoriteHeader: 'My favorites'
        },
        label: {
            total: 'Total',
            subtotal: 'Subtotal',
            tax: 'Tax ({percent}%)',
            articleChoiceLabel1: 'Change/add ingredients',
            articleChoiceLabel2: 'Change',
            articleChoiceLabel3: 'Choose extra accessories',
            articleChoiceLabel4: 'Change',
            articleChoiceLabel5: 'Choose flavor',
            dineInPlace: 'Eat here',
            takeAway: 'Take away',
            now: 'Now'
        },
        order: {
            header: 'My order',
            pageHeader: 'Your order',
            thankYouText: 'Thanks for your order',
            infoText: '(Tap on product to change)',
            infoText2: '(Discounts are deducted in the next step)',
            emptyInfoText: 'No items in the basket yet',
            selectedRestaurant: 'Selected restaurant',
            missing: 'Order is missing',
            pickUpLabel: 'Estimated pick up time',
            statusLabel: 'current status',
            numberLabel: 'Your order number',
            note: "Delivery to:",
            status: {
                calculatedprice: {
                    label: "Verifying order...",
                    description: ""
                },
                added: {
                    label: 'Your order is received',
                    description: "Your order is received and is being processed."
                },
                started: {
                    label: 'Your order is being prepared!',
                    description: "Your order is being prepared."
                },
                finnished: {
                    label: 'Ready for pickup!',
                    description: "Your order is ready for pickup."
                },
                delivered: {
                    label: "Delivered!",
                    description: "We hope you enjoy your meal."
                },
                rollbacked: {
                    label: "Order cancelled, the reservation on your card has been removed, if you have payed with Swish, please contact the restaurant for a refund.",
                    description: ''
                }
            },
            campaignLabel: 'Campaign'
        },
        loader: {
            text: 'Loading...',
            languages: 'Looking for language options',
            categories: 'Looking for categories',
            image: '',
            calculatesDistances: 'Calculates distances',
            checkoutSummary: 'Contacting the restaurant',
        },
        cancel: {
            header: 'Cancel',
            text: 'Do you want to cancel?',
            order: {
                header: 'Cancel order',
                description: 'Do you want to cancel your order?',
                confirm: 'Yes, cancel',
                cancel: 'No, continue',
            }
        },
        error: {
            heading: 'Error message',
            server: 'There were a server error, please try again.',
            badRequest: 'Your browser sent a request that this server could not understand',
            unknown: 'Unknown error, please try again.',
            "One or more validation errors occurred.": "One or more validation errors occurred.",
            PasswordRequiresNonAlphanumeric: "Password must have at least one non alphanumeric character.",
            PasswordRequiresDigit: "Password must have at least one digit ('0'-'9').",
            PasswordRequiresLower: "Password must have at least one lowercase ('a'-'z').",
            PasswordRequiresUpper: "Password must have at least one uppercase ('A'-'Z').",
            PasswordTooShort: "The password needs to be atleast 6 characters",
            PasswordMismatch: "Passwords do not match.",
            DuplicateUserName: "An account with this email already exists.",
            DuplicateEmail: "Try to login or forgot password insted.",
            order: {
                "unavailable-products": "One or more of the products in your order is no longer available for sale.",
                "shop-closed": "The shop you are ordering from are no longer open, try again later.",
                "shop-closed-at-pickuptime": "The shop is closed at your specified pickup time",
                "shop-paused": "The shop you are ordering from are sadly not accepting any online orders right now, try again later.",
                "create-order-failed": "For some reason we could not create your order, please try again later.",
            },
            payment: {
                failed: 'An unknown error occured at payment (code:01)',
                aborted: 'An unknown error occured at payment (code:02)',
                notPaid: 'An unknown error occured at payment (code:03)',
                invalid: 'An unknown error occured at payment (code:04)',
                accesstokenMissing: 'An unknown error occured at payment (code:05)',
                IdentifierMissing: 'An unknown error occured at payment (code:06)',
                NotFound: 'An unknown error occured at payment (code:07)',
                merchantNotFound: 'An unknown error occured at payment (code:08)',
                orderNotFound: 'An unknown error occured at payment (code:09)',
                returnUrl: 'An unknown error occured at payment (code:10)',
                requestParametersMissing: 'An unknown error occured at payment (code:11)',
                orderFailed: 'An unknown error occured at payment (code:12)',
                captureFailed: 'An unknown error occured at payment (code:13)',
                createError: 'An unknown error occured at payment (code: 14)',
                loadScriptError: 'An unknown error occured at payment (kod: 15)',
            },
            user: {
                "invalid-password": "The password provided does not meet the minimum requirements.",
                "register-user-failure": "Failed to create account",
            }
        },
        privacyPolicy: {
            header: 'Privacy policy'
        },
        receipt: {
            heading: "Receipt",
            total: "Total",
            vat: "VAT",
            net: "Net",
            orderNumber: "OrderNr",
            paymentId: "Payment id",
            note: "Delivery to",
            email: "Email:",
            emailPlaceHolder: "Enter your email address",
            sendEmail: "Send receipt",
            emailSent: "Email sent",
            deliveryDate: "Delivery time",
        },
        article: {
            allergens: 'Allergic? Talk to our staff and we will help you.'
        },
        offline: {
            header: 'Offline',
            text: 'You are offline, please check your internet connection and try again.',
            button: 'Try again'
        },
        login: {
            header: 'Sign in',
            email: 'E-mail',
            password: 'Password',
            registerText: 'Don´t have an account yet?',
            registerLink: 'Create new account',
            confirm: 'log in',
            forgotPassword: 'Forgot password',
            separatorText: 'Or',
            failed: 'Failed to login, please try again',
            googleSignIn: 'Sign in with Google',
            facebookSignIn: 'Sign in with Facebook'
        },
        forgotPassword: {
            header: 'Forgot password',
            text: 'Have you forgotten your password? Enter your email to receive a recovery email.',
            email: {
                label: 'E-mail',
                placeholder: 'E-mail'
            },
            send: {
                text: 'Send'
            },
            sent: {
                header: 'E-mail sent',
                message: 'A recovery email has been sent to the specified e-mail, if you have not received an e-mail within a few minutes, there is no account linked to the specified e-mail.',
                newAccountText: 'Create an account'
            },
        },
        resetPassword: {
            header: 'Reset password',
            password: { 
                label: 'New password',
                placeholder: 'Enter new password'
            },
            confirmPassword: {
                label: 'Confirm new password',
                placeholder: 'Enter the new password again'
            },
            send: {
                text: 'Reset password'
            },
            reset: {
                header: 'Password reset',
                message: 'Your password has been reset, you can now log in with your new password.'
            }
        },
        campaign: {
            bannerText: 'Campaign'
        },
        memberCard: {
            header: 'My digital club-card',
            text: 'Scan me on the kiosk or at the pos'

        },
        favorites: {
            header: 'My favorites',
            empty: 'You have no favorites yet'
        },
        orderHistory: {
            header: 'Order history',
            empty: 'Your order history is empty'
        },
        register: {
            header: 'Create account',
            success: {
                header: 'Account created',
                text: 'An email has been sent to the specified email address, please check your inbox and follow the instructions to confirm your email address. After that is completed you can sign in.',
                loginLink: 'Sign in'
            },
            firstname: {
                label: 'Firstnamn',
                placeholder: 'Enter your firstname'
            },
            lastname: {
                label: 'Lastname',
                placeholder: 'Enter your lastname'
            },
            email: {
                label: 'Email',
                placeholder: 'Enter email'
            },
            password: {
                label: 'Password',
                placeholder: 'Enter password'
            },
            confirmPassword: {
                label: 'Repeat password',
                placeholder: 'Repeat the password'
            },
            acceptTerms: {
                label: 'I accept the ',
                linkText: 'terms and conditions'
            },
            registerButton: 'Create account'
        },
        confirmEmail: {
          header: "Confirm email",
            text: "Your account has been verified, you can now log in",  
        },
        navBar: {
            login: 'Sign in'
        },
        campaigns: {
            header: 'Offers',
            description: 'Below you see all the offers that are active right now, if you are logged in there may be more offers',
            descriptionAuthenticated: 'Below you see all the offers that are active right now.',
            membersOnly: {
                text: 'Membership offer'
            },
            localOffer: {
                text: 'Local offer'
            },
            offer: {
                text: 'Offer'
            },
            toArticle: {
                text: 'To the offer'
            },
            expandButton: {
                text: "Show more"
            },
        },
        profile: {
            edit: {
                header: "Edit Profile",
                submit: {
                    text: "Save",
                    title: "Save the profile"
                },
                userInformation: {
                    header: "User information",
                    name: {
                        label: "Name",
                        placeholder: "Enter your name",
                        description: "Your name will be displayed in the app"
                    },
                    lastName: {
                        label: "Surname",
                        placeholder: "Enter your surname",
                        description: "Your name will be displayed in the app"
                    },
                    email: {
                        label: "Email",
                        placeholder: "Enter your Email",
                        description: "Your email will be used to sign in to the app"
                    },
                },
                changePassword: {
                    header: "Change password",
                    password: {
                        label: "Current password",
                        placeholder: "Enter your current password",
                        description: "The password you currently use to sign in to the application"
                    },
                    newPassword: {
                        label: "New password",
                        placeholder: "Enter your new password",
                        description: "The password you whant to chage to"
                    },
                    confirmNewPassword: {
                        label: "Confirm the new password",
                        placeholder: "Enter your new password again",
                        description: "Confirm the password you whant to chage to"
                    },
                },
                emailConfirmationSent: "An email has been sent to the specified email address, please check your inbox and follow the instructions to confirm your email address.",
                deleteAccount: {
                    text: "Delete account",
                    title: "Delete your account",
                    confirm: {
                        header: "Delete account?",
                        description: "Are you really sure that you want to delete your account?",
                        ok: "Yes, delete",
                        cancel: "Cancel"
                    }
                }
            },
            menu: {
                userGreeting: 'Hi!', 
                offers: 'Offers',
                favorites: 'My favorites',
                orderHistory: 'Order History',
                account: 'My accont',
                logout: 'Sign out'
            },
            
            "confirm-email": {
                header: "Confirm email",
                text: "Your account has been verified, you can now log in",
            }
        },
    },
    sv: {
        home: {
            header: 'Välkommen!',
            subHeader: 'Välj din restaurang'
        },
        list: {
            menu: 'Meny'
        },
        shopchanged: {
            header: 'Varning!',
            text: 'Du har en pågående beställning kopplad till en annan restaurang än den nuvarande.'
        },
        checkout: {
            header: 'Kassa',
            pickup: 'När ska maten vara klar?',
            orderHeader: 'Min beställning',
        },
        payment: {
            header: 'Betala'
        },
        termsAndConditions: {
            header: 'Allmänna villkor'
        },
        suggestedArticles: {
            header: 'Får vi erbjuda något annat?',
        },
        selectedShop: {
            header: 'Välj var du vill äta',
            extraInfo: ''
        },
        search: {
            label: 'Sök restaurang',
            placeholder: 'Hitta restaurang',
            noMatch: 'Ingen matchande restaurang'
        },
        shopSelector: {
            singleShop: {
                start: "Beställ här",
                closed: "Stängt för idag",
                paused: "Stängt för beställning",
                openingHoursHeader: "Dagens öppettider på webben",
            }
        },
        button: {
            showMore: 'Visa mer',
            showMoreRestaurants: 'Visa fler restauranger',
            open: 'Öppet',
            closed: 'Stängt',
            paused: 'Stängt på webben',
            dineInPlace: 'På restaurangen',
            takeAway: 'Ta med',
            pay: 'Betala',
            order: 'Beställ',
            change: 'Ändra',
            noThanks: 'Nej tack',
            done: 'Klar',
            cancel: 'Avbryt',
            changeRestaurant: 'Byt restaurang',
            changeLanguage: 'Byt språk',
            cancelOrder: 'Avbryt beställningen',
            yes: 'Ja',
            no: 'Nej',
            add: 'Lägg till',
            back: 'Tillbaka',
            backToCategories: 'Tillbaka till menyn',
            backToCategory: 'Tillbaka till {category}',
            backToArticle: 'Tillbaka till {article}',
            backToHome: 'Avbryt beställning',
            backToShopPage: 'Avbryt beställning',
            receipt: 'Kvitto'
        },
        item: {
            notAvalible: 'Tillfälligt slut',
            favoriteHeader: 'Mina favoriter'
        },
        label: {
            total: 'Totalt',
            subtotal: 'Netto',
            tax: 'Varav moms ({percent}%)',
            dineInPlace: 'Äta på restaurangen',
            takeAway: 'Ta med',
            articleChoiceLabel1: 'Anpassa',
            articleChoiceLabel2: 'Byt tillbehör',
            articleChoiceLabel3: 'Lägg till extra tillbehör',
            articleChoiceLabel4: 'Ändra dryck',
            articleChoiceLabel5: 'Välj smak',
            now: 'Så snart som möjligt',
        },
        order: {
            header: 'Min beställning',
            pageHeader: 'Din beställning',
            thankYouText: 'Tack för din beställning',
            infoText: '(Tryck på produkt för att ändra)',
            infoText2: '(ev. rabatter dras i nästa steg)',
            emptyInfoText: 'Inga varor i korgen ännu',
            selectedRestaurant: 'Vald restaurang',
            missing: 'Beställningen saknas',
            pickUpLabel: 'Beräknad hämtningstid',
            statusLabel: 'Nuvarande status',
            numberLabel: 'Ditt beställningsnummer',
            note: "Levereras till:",
            status: {
                calculatedprice: {
                    label: "Kontrollerar beställning...",
                    description: ""
                },
                added: {
                    label: 'Mottagen',
                    description: "Din beställning är mottagen"
                },
                started: {
                    label: 'Tillagas!',
                    description: "Din beställning tillagas."
                },
                finnished: {
                    label: 'Klart',
                    description: "Din beställning är redo att hämtas"
                },
                delivered: {
                    label: "Upphämtad",
                    description: "Hoppas maten smakar"
                },
                rollbacked: {
                    label: "Avbruten",
                    description: "Din beställning är avbryten. Reservationen på kortet är borttagen, har du betalat med Swish bör du kontakta restaurangen för återbetalning."
                }
            },
            campaignLabel: 'Erbjudande'
        },
        loader: {
            text: 'Laddar...',
            languages: 'Letar efter språkval',
            categories: 'Letar efter kategorier',
            image: '',
            calculatesDistances: 'Beräknar avstånd',
            checkoutSummary: 'Kontaktar restaurangen',
        },
        cancel: {
            header: 'Avbryt',
            text: 'Vill du verkligen avbryta?',
            order: {
                header: 'Avbryt beställning',
                description: 'Vill du avbryta din beställning?',
                confirm: 'Ja',
                cancel: 'Nej, fortsätt',
            }
        },
        error: {
            heading: 'Felmeddelande',
            server: 'Det uppstod ett serverfel, försök igen.',
            badRequest: 'Din webbläsare skickade en begäran som den här servern inte kunde förstå.',
            notFound: 'Sidan kunde inte hittas. Detta kan bero på att artikeln eller sidan inte finns tillgänlig för denna restaurang.',
            unknown: 'Okänt fel, försök igen. ',
            "One or more validation errors occurred.": "One or more validation errors occurred.",
            PasswordRequiresNonAlphanumeric: "Lösenordet måste innehålla minst ett specialtecken.",
            PasswordRequiresDigit: "Lösenordet måste innehålla minst en siffra ('0'-'9').",
            PasswordRequiresLower: "Lösenordet måste innehålla minst en liten bokstav ('a'-'z').",
            PasswordRequiresUpper: "Lösenordet måste innehålla minst en stor bokstav ('A'-'Z').",
            PasswordTooShort: "Lösenordet måste innehålla minst 6 tecken",
            PasswordMismatch: "Lösenorden matchar inte.",
            DuplicateUserName: "Ett konto med denna e-postadress finns redan.",
            DuplicateEmail: "Testa logga in eller glömt lösenord istället.",
            order: {
                "unavailable-products": "Någon av produkterna i din varukorg är inte längre tillgängliga för beställning",
                "shop-closed": "Butiken du försöker beställa till är stängd, försök igen senare.",
                "shop-closed-at-pickuptime": "Butiken du försöker beställa till är stängd vid den angivna upphämtningstiden",
                "shop-paused": "Butiken du försöker beställa till tar tyvärr inte emot beställningar via webben just nu, försök igen senare.",
                "create-order-failed": "Tyvärr gick det för närvarande inte att skapa din beställing, var god försök igen senare.",
            },
            payment: {
                failed: 'Det uppstod tyvärr ett fel vid betalningen (kod:01)',
                aborted: 'Det uppstod tyvärr ett fel vid betalningen (kod:02)',
                notPaid: 'Det uppstod tyvärr ett fel vid betalningen (kod:03)',
                invalid: 'Det uppstod tyvärr ett fel vid betalningen (kod:04)',
                accesstokenMissing: 'Det uppstod tyvärr ett fel vid betalningen (kod:05)',
                IdentifierMissing: 'Det uppstod tyvärr ett fel vid betalningen (kod:06)',
                NotFound: 'Det uppstod tyvärr ett fel vid betalningen (kod:07)',
                merchantNotFound: 'Det uppstod tyvärr ett fel vid betalningen (kod:08)',
                orderNotFound: 'Det uppstod tyvärr ett fel vid betalningen (kod:09)',
                returnUrl: 'Det uppstod tyvärr ett fel vid betalningen (kod:10)',
                requestParametersMissing: 'Det uppstod tyvärr ett fel vid betalningen (kod:11)',
                orderFailed: 'Det uppstod tyvärr ett fel vid betalningen (kod:12)',
                captureFailed: 'Det uppstod tyvärr ett fel vid betalningen (kod:13)',
                createError: 'Det uppstod tyvärr ett fel vid betalningen (kod: 14)',
                loadScriptError: 'Det uppstod tyvärr ett fel vid betalningen (kod: 15)',
            },
            user: {
                "invalid-password": "Lösenordet uppfyller inte de minimikrav som ställs.",
                "register-user-failure": "Misslyckades att skapa konto",
            }
        },
        privacyPolicy: {
            header: 'Sekretess policy',
            policy: "                            <p>Vi på Winpos respekterar din integritet och har åtagit oss att skydda din personliga information. Denna integritetspolicy beskriver den information vi samlar in, hur vi använder den och hur vi skyddar den i samband med din användning av vår weborder/app.</p>\n" +
                "                            <h3>Information vi samlar in</h3>\n" +
                "                            <p>Vi kan samla in personuppgifter som du tillhandahåller när du använder vår weborder/app, till exempel din IP-adress. Vi kan också samla in icke-personlig information, till exempel din enhetstyp och användningsdata.</p>\n" +
                "                            <h3>Hur vi använder din information</h3>\n" +
                "                            <p>Vi använder den personliga information vi samlar in för att uppfylla din beställning, tillhandahålla kundsupport och kommunicera med dig om vår weborder/app. Vi kan också använda din information för att skicka dig marknadsföringskommunikation om våra andra produkter och tjänster.</p>\n" +
                "                            <p>Vi kan dela din information med tredje parts tjänsteleverantörer som hjälper oss att uppfylla din beställning och tillhandahålla kundsupport. Vi kan också dela din information med våra dotterbolag och partners för marknadsföringsändamål.</p>\n" +
                "                            <h3>Rättslig grund för behandling</h3>\n" +
                "                            <p>Enligt GDPR behandlar vi dina personuppgifter på den rättsliga grunden för ditt samtycke eller för att uppfylla ett avtal med dig. Vi har också ett berättigat intresse av att behandla din information för kundsupport och marknadsföringsändamål.</p>\n" +
                "                            <h3>Hur vi skyddar din information</h3>\n" +
                "                            <p>Vi vidtar rimliga åtgärder för att skydda din personliga information från obehörig åtkomst, avslöjande, ändring och förstörelse. Vi använder säkerhetsåtgärder av branschstandard, såsom kryptering och säkra servrar, för att skydda din information.</p>\n" +
                "                            <h3>Dina rättigheter</h3>\n" +
                "                            <p>Enligt GDPR har du rätt att få tillgång till, korrigera, radera och begränsa behandlingen av dina personuppgifter. Du har också rätt att invända mot behandlingen av dina personuppgifter för marknadsföringsändamål.</p>\n" +
                "                            <p>Om du vill utöva någon av dessa rättigheter, vänligen kontakta oss på info@winpos.se. Vi kommer att svara på din förfrågan inom 30 dagar.</p>\n" +
                "                            <h3>Uppdateringar av denna sekretesspolicy</h3>\n" +
                "                            <p>Vi kan uppdatera denna sekretesspolicy från tid till annan för att återspegla förändringar i vår praxis eller tillämplig lag. Vi kommer att meddela dig om eventuella väsentliga ändringar genom att publicera den uppdaterade sekretesspolicyn på vår webbplats eller via vår weborder/app.</p>\n" +
                "                            <h3>Kontakta oss</h3>\n" +
                "                            <p>Om du har några frågor eller funderingar om denna sekretesspolicy eller vår GDPR-efterlevnad, vänligen kontakta oss på info@winpos.se.</p>\n" +
                "                            <p>Ikraftträdandedatum: 2023-05-01</p>"
        },
        receipt: {
            heading: "Kvitto",
            total: "Totalt",
            vat: "Moms",
            net: "Netto",
            orderNumber: "Beställnings nr",
            paymentId: "Betalnings id",
            note: "Levereras till",
            email: "E-post:",
            emailPlaceHolder: "Skriv in din e-postadress",
            sendEmail: "Skicka kvitto",
            emailSent: "Kvitto skickat",
            deliveryDate: "Leveranstid",
        },
        article: {
            allergens: 'Allergisk? Prata med oss i personalen så hjälper vi dig.'
        },
        offline: {
            header: 'Det går inte ansluta till internet',
            text: 'Kontrollera din internetanslutning och försök igen.',
            button: 'Försök igen'
        },
        login: {
            header: 'Logga in',
            email: 'E-post',
            password: 'Lösenord',
            registerText: 'Saknar du konto?',
            registerLink: 'Skapa konto',
            confirm: 'Logga in',
            forgotPassword: 'Glömt lösenord',
            separatorText: 'Eller',
            failed: 'Misslyckades att logga in, försök igen',
            googleSignIn: 'Logga in med Google',
            facebookSignIn: 'Logga in med Facebook'
        },
        forgotPassword: {
            header: 'Glömt lösenord',
            text: 'Har ni glömt ert lösenord? Fyll i din e-post för att få ett återställningsmejl.',
            email: {
                label: 'Ange e-post',
                placeholder: 'E-post'
            },
            send: {
                text: 'Skicka'
            },
            sent: {
                header: 'Skickat',
                message: 'Ett återställningsmail har skickats till angivna epost, har ni inte fått något mail inom några minuter finns det inget konto kopplat till angiven e-post.',
                newAccountText: 'Skapa ett konto'
            },
        },
        resetPassword: {
            header: 'Återställ lösenordet',
            password: {
                label: 'Nytt lösenord',
                placeholder: 'Ange ett nytt lösenord'
            },
            confirmPassword: {
                label: 'Bekräfta det nya lösenordet',
                placeholder: 'Ange det nya lösenordet igen'
            },
            send: {
                text: 'Återställ'
            },
            reset: {
                header: 'Lösenordet återställt',
                message: 'Ditt lösenord har återställts, du kan nu logga in med ditt nya lösenord.'
            }
        },
        campaign: {
            bannerText: 'Erbjudande'
        },
        memberCard: {
            header: 'Mitt digitala klubb-kort',
            text: 'Skanna mig i kassan eller i självbetjänten'
        },
        orderHistory: {
            header: 'Beställningshistorik',
            empty: 'Du har ingen beställningshistorik ännu'
        },
        favorites: {
            header: 'Mina favoriter',
            empty: 'Du har inga favoriter ännu'
        },
        register: {
            header: 'Skapa konto',
            success: {
                header: 'Konto skapat',
                text: 'Ett mail har skickats till angiven e-post, vänligen kolla din inkorg och följ instruktionerna för att bekräfta din e-postadress. Efter det kan du logga in.',
                loginLink: 'Logga in'
            },
            firstname: {
                label: 'Förnamn',
                placeholder: 'Ange förnamn'
            },
            lastname: {
                label: 'Efternamn',
                placeholder: 'Ange efternamn'
            },
            email: {
                label: 'E-post',
                placeholder: 'Ange e-post'
            },
            password: {
                label: 'Lösenord',
                placeholder: 'Ange lösenord'
            },
            confirmPassword: {
                label: 'Upprepa lösenordet',
                placeholder: 'Upprepa lösenordet'
            },
            acceptTerms: {
                label: 'Jag godkänner ',
                linkText: 'villkoren'
            },
            passwordMismatch: {
                text:'Lösenorden matchar inte'
            },
            registerButton: 'Skapa konto'

        },
        confirmEmail: {
            header: "E-post bekräftad",
            text: "Ditt konto har bekräftats, du kan nu logga in.",
        },
        navBar: {
            login: 'Logga in'
        },
        campaigns: {
            header: 'Erbjudanden just nu',
            description: 'Nedan ser du alla erbjudanden som är aktiva just nu, är du inloggad så kan det finnas fler erbjudanden.',
            descriptionAuthenticated: 'Nedan ser du alla erbjudanden som är aktiva just nu.',
            membersOnly: {
                text: 'Medlemserbjudande'
            },
            localOffer: {
                text: 'Lokalt erbjudande'
            },
            offer: {
                text: 'Erbjudande'
            },
            toArticle: {
                text: 'Till erbjudandet'
            },
            expandButton: {
                text: "Visa mer"
            },
        },
        profile: {
            edit: {
                header: "Mitt konto",
                submit: {
                    text: "Spara",
                    title: "Spara kontouppgifter"
                },
                userInformation: {
                    header: "Användarinformation",
                    name: {
                        label: "Namn",
                        placeholder: "Skriv in ditt namn",
                        description: "Ditt namn kommer visas på ditt konto"
                    },
                    lastName: {
                        label: "Efternamn",
                        placeholder: "Skriv in ditt efternamn",
                        description: "Ditt efternamn kommer visas på ditt konto"
                    },
                    email: {
                        label: "E-post",
                        placeholder: "Skriv in din e-post",
                        description: "Din e-post använder du när du loggar in"
                    },
                },
                changePassword: {
                    header: "Byt lösenord",
                    password: {
                        label: "Nuvarande lösenord",
                        placeholder: "Skriv in ditt nuvarande lösenord",
                        description: "Skriv in det lösenord du använder idag för att logga in"
                    },
                    newPassword: {
                        label: "Nytt lösenord",
                        placeholder: "Skriv in ditt nya lösenord",
                        description: "Det lösenord som du vill byta till"
                    },
                    confirmNewPassword: {
                        label: "Bekräfta det nya lösenordet",
                        placeholder: "Skriv in det nya lösenordet igen",
                        description: "Det lösenord som du vill byta till, igen"
                    },
                },
                emailConfirmationSent: "Ett mail har skickats till angiven e-post, vänligen kolla din inkorg och följ instruktionerna för att bekräfta din e-postadress.",
                deleteAccount: {
                    text: "Ta bort konto",
                    title: "Ta bort ditt konto från tjänsten",
                    confirm: {
                        header: "Ta bort konto??",
                        description: "Är du verkligen helt säker på att du vill ta bort kontot?",
                        ok: "Ja, ta bort",
                        cancel: "Avbryt"
                    }
                }
            },
            menu: {
                userGreeting: 'Hej!', 
                offers: 'Erbjudanden',
                favorites: 'Mina favoriter ',
                orderHistory: 'Beställningshistorik',
                account: 'Mitt konto',
                logout: 'Logga ut'
            },
        }
    },
    fi: {
        home: {
            header: 'Tervetuloa!',
            subHeader: 'Valitse ravintola',
        },
        list: {
            menu: 'Menu'
        },
        shopchanged: {
            header: 'Varoitus!',
            text: 'Sinulla on odottava tilaus toisessa ravintolassa kuin nyt valitussa ravintolassa.'
        },
        checkout: {
            header: 'Kassa',
            pickup: 'Noutoaika',
            orderHeader: 'Tilaukseni',
        },
        payment: {
            header: 'Maksa tilaus'
        },
        termsAndConditions: {
            header: 'Käyttöehdot'
        },
        suggestedArticles: {
            header: 'Voimmeko tarjota jotain muuta?',
        },
        selectedShop: {
            header: 'Valitse missä haluat syödä',
        },
        search: {
            label: 'Etsi ravintola',
            placeholder: 'Löydä ravintola',
            noMatch: 'Vastaavia ravintoloita ei löytynyt'
        },
        shopSelector: {
            singleShop: {
                start: "Aloita tilaus",
                closed: "Suljettu tältä päivältä",
                paused: "Suljettu tilauksilta",
                openingHoursHeader: "Aukioloajat",
            }
        },
        button: {
            showMore: 'Näytä enemmän',
            showMoreRestaurants: 'Näytä enemmän ravintoloita',
            open: 'Auki',
            closed: 'Suljettu',
            paused: 'Suljettu tilauksilta',
            dineInPlace: 'Syön ravintolassa',
            takeAway: 'Otan mukaan',
            pay: 'Maksa',
            order: 'Tilaa',
            change: 'Muokkaa',
            noThanks: 'Ei kiitos',
            done: 'Valmis',
            cancel: 'Peruuta',
            changeRestaurant: 'Vaihda ravintola',
            changeLanguage: 'Vaihda kieli',
            cancelOrder: 'Peru tilaus',
            yes: 'Kyllä',
            no: 'Ei',
            add: 'Lisää',
            back: 'Takaisin',
            receipt: 'Kuitti'

        },
        item: {
            notAvalible: 'tuote loppu',
        },
        label: {
            total: 'Yhteensä',
            subtotal: 'Välisumma',
            tax: 'Alv ({percent}%)',
            articleChoiceLabel1: 'Muokkaa/lisää',
            articleChoiceLabel2: 'Muokkaa',
            articleChoiceLabel3: 'Valitse lisukkeet',
            articleChoiceLabel4: 'Muokkaa',
            articleChoiceLabel5: 'Valitse maku',
            dineInPlace: 'Syön ravintolassa',
            takeAway: 'Otan mukaan',
            now: 'Nyt'
        },
        order: {
            header: 'Tilaukseni',
            pageHeader: 'Tilauksesi',
            thankYouText: 'Kiitos tilauksestasi',
            infoText: '(Napauta tuotetta tehdäksesi muutoksia)',
            emptyInfoText: 'Ei vielä tuotteita ostoskorissa',
            selectedRestaurant: 'Valittu ravintola',
            missing: 'Tilaus puuttuu',
            pickUpLabel: 'Arvioitu noutoaika',
            statusLabel: 'nykyinen tila',
            numberLabel: 'Tilausnumerosi',
            note: "Toimitus osoitteeseen:",
            status: {
                processing: "Vahvistetaan tilausta...",
                received: 'Tilauksesi on vastaanotettu',
                started: 'Tilaustasi valmistellaan!',
                readyforpickup: 'Valmis noudettavaksi!',
                delivered: "Toimitettu!",
                rollbacked: "Tilaus peruttu, korttisi varaus on poistettu. Jos olet maksanut Swishillä, ota yhteyttä ravintolaan rahan palautusta varten."
            }
        },
        loader: {
            text: 'Ladataan...',
            languages: 'Etsitään kielivaihtoehtoja',
            categories: 'Etsitään kategorioita',
            image: '',
            calculatesDistances: 'Lasketaan etäisyydet',
        },
        cancel: {
            header: 'Peruutus',
            text: 'Haluatko perua tilauksen?',
            order: {
                header: 'Peru tilaus',
                description: 'Haluatko perua tilauksesi?',
                confirm: 'Kyllä, perun tilauksen',
                cancel: 'Ei, jatka',
            }
        },
        error: {
            heading: 'Virheviesti',
            server: 'Palvelinvirhe, yritä uudelleen.',
            badRequest: 'Selaimesi lähetti pyynnön, jota tämä palvelin ei ymmärtänyt.',
            unknown: 'Tuntematon virhe, yritä uudelleen.',
            order: {
                "unavailable-products": "Yksi tai useampi tilauksesi tuotteista ei ole enää saatavilla.",
                "shop-closed": "Ravintola ei ole enää auki, yritä myöhemmin uudelleen.",
                "shop-closed-at-pickuptime": "Ravintola on suljettu valitsemanasi noutoaikana.",
                "shop-paused": "Ravintola ei valitettavasti ota vastaan verkkotilauksia juuri nyt, yritä myöhemmin uudelleen.",
                "create-order-failed": "Jostain syystä emme voineet luoda tilaustasi, yritä myöhemmin uudelleen.",
            },
            payment: {
                failed: 'Maksun yhteydessä tapahtui tuntematon virhe (code:01)',
                aborted: 'Maksun yhteydessä tapahtui tuntematon virhe (code:02)',
                notPaid: 'Maksun yhteydessä tapahtui tuntematon virhe (code:03)',
                invalid: 'Maksun yhteydessä tapahtui tuntematon virhe (code:04)',
                accesstokenMissing: 'Maksun yhteydessä tapahtui tuntematon virhe (code:05)',
                IdentifierMissing: 'Maksun yhteydessä tapahtui tuntematon virhe (code:06)',
                NotFound: 'Maksun yhteydessä tapahtui tuntematon virhe (code:07)',
                merchantNotFound: 'Maksun yhteydessä tapahtui tuntematon virhe (code:08)',
                orderNotFound: 'Maksun yhteydessä tapahtui tuntematon virhe (code:09)',
                returnUrl: 'Maksun yhteydessä tapahtui tuntematon virhe (code:10)',
                requestParametersMissing: 'Maksun yhteydessä tapahtui tuntematon virhe (code:11)',
                orderFailed: 'Maksun yhteydessä tapahtui tuntematon virhe (code:12)',
                captureFailed: 'Maksun yhteydessä tapahtui tuntematon virhe (code:13)',
                createError: 'Maksun yhteydessä tapahtui tuntematon virhe (code: 14)',
                loadScriptError: 'Maksun yhteydessä tapahtui tuntematon virhe (kod: 15)',
            }
        },
        privacyPolicy: {
            header: 'Tietosuojakäytäntö'
        },
        receipt: {
            heading: "Kuitti",
            total: "Yhteensä",
            vat: "Alv",
            net: "Netto",
            orderNumber: "TilausNro",
            paymentId: "Maksutunnus",
            note: "Toimitus osoitteeseen",
            email: "Sähköpostiosoite:",
            emailPlaceHolder: "Syötä sähköpostiosoitteesi",
            sendEmail: "Lähetä kuitti",
            emailSent: "Sähköposti lähetetty",
            deliveryDate: "Toimitusajankohta",
        },
        article: {
            allergens: 'Tietoa allergeeneista <a href="#">somewhere</a>'
        }
    },
    ru: {
        home: {
            header: 'Добро пожаловать! Выберите свой ресторан',
        },
        checkout: {
            header: 'Kassan',
            text: 'Lorem ipsum dolor sit amet, consectetur adipiscing elit, sed do eiusmod tempor incididunt ut labore et dolore magna aliqua.'
        },
        suggestedArticles: {
            header: 'Får vi erbjuda något annat?',
        },
        selectedShop: {
            header: 'Выберите, где поесть'
        },
        search: {
            label: 'Поиск ресторана',
            placeholder: 'Найти ресторан',
            noMatch: 'Нет подходящего ресторана'
        },
        button: {
            showMore: 'Visa mer',
            showMoreRestaurants: 'Показать больше ресторанов',
            open: 'открыть',
            closed: 'Закрыто',
            paused: 'Stängt på webben',
            dineInPlace: 'Есть здесь',
            takeAway: 'Приносить',
            pay: 'Betala',
            order: 'Beställ',
            change: 'Ändra',
            noThanks: 'Nej tack',
            done: 'Klar',
            cancel: 'Avbryt',
            changeRestaurant: 'Изменить ресторан',
            changeLanguage: 'переключить язык',
            cancelOrder: 'Avbryt order',
            yes: 'Да',
            no: 'Нет'
        },
        label: {
            total: 'Totalt',
            dineInPlace: 'Äta här',
            takeAway: 'Ta med',
            articleChoiceLabel1: 'Ändra/lägg till ingredienser',
            articleChoiceLabel2: 'Ändra',
            articleChoiceLabel3: 'Lägg till extra tillbehör',
            articleChoiceLabel4: 'Ändra',
            articleChoiceLabel5: 'Välj smak',
        },
        order: {
            header: 'Min beställning',
            infoText: '(Tryck på produkt för att ändra)',
            emptyInfoText: 'Inga varor i korgen ännu'
        },
        loader: {
            text: 'Letar efter hamburgare',
            languages: 'Letar efter språkval',
            categories: 'Letar efter kategorier',
            image: ''
        },
        cancel: {
            header: 'Avbryt',
            text: 'Vill du verkligen avbryta?',
            order: {
                header: 'Avbryt beställning',
                description: 'Vill du avbryta din beställning?',
                confirm: 'Ja, avbryt',
                cancel: 'Nej, fortsätt',
            }
        }
    }
}

const defaultLanguage = () => {
    return new Promise((resolve: any) => {
        axios.post<string>(`/api/cultures/setdefaultculture`)
            .then(response => response.data)
            .finally(() => resolve());
    });
};

const getLocaleFromCookie = ()=>{
    const cookie = document.cookie.split(';').find(c=>c.includes('.AspNetCore.Culture'))
    let culture = 'sv';
    if(cookie){
        const cookieValue = decodeURIComponent(cookie.split('=')[1]);
        const cultureValues = cookieValue.split('|');
        if(cultureValues.length > 1){
            culture = cultureValues[1].split('=')[1]; //uic value of aspnet core culture cookie
        }
    }
    console.log('culture', culture);
    return culture;
}


export default createI18n({
    locale: getLocaleFromCookie(),
    fallbackLocale: 'en',
    legacy: false,
    messages
})