import {createRouter, createWebHistory,} from '@ionic/vue-router';
import {RouteRecordRaw} from 'vue-router';
import Offline from "@/views/Offline.vue";
import HomePage from "@/views/HomePage.vue";

const routes: Array<RouteRecordRaw> = [
  {
    name: 'home',
    path: '',
    component: HomePage, //this cannot be an import statement for some reason, fails to load in production build
    meta: {
      confirmCartReset: true
    }
  },
  {
    name: 'offline',
    path: '/offline',
    component: Offline,
  },
  {
    name: 'login',
    'path': '/login',
    component: () => import('@/views/LoginPage.vue'),
    meta: {
      skipIfLoggedIn: true
    }
  },
  {
    name: 'register',
    'path': '/register',
    component: () => import('@/views/RegisterPage.vue')
  },
  {
    name: 'favorites',
    'path': '/favorites',
    component: () => import('@/views/Favorites.vue'),
    meta: {
      requiresAuth: false,
      showCart: true
    }
  },
  {
    name: 'orderHistory',
    'path': '/order-history',
    component: () => import('@/views/OrderHistory.vue'),
    meta: {
      requiresAuth: true,
      showCart: true
    }
  },
  {
    name: 'profile',
    'path': '/profile',
    component: () => import('@/views/ProfilePage.vue'),
    meta: {
      requiresAuth: true
    }
  },
  {
    name: 'forgotPassword',
    'path': '/forgot-password',
    component: () => import('@/views/ForgotPasswordPage.vue')
  },
  {
    name: 'resetPassword',
    'path': '/reset-password',
    component: () => import('@/views/ResetPasswordPage.vue')
  },
  {
    name: 'confirmEmail',
    'path': '/confirm-email',
    component: () => import('@/views/ConfirmEmailPage.vue')
  },
  {
    name: 'campaigns',
    path: '/campaigns',
    component: () => import('@/views/CampaignsPage.vue'),
    meta: {
      showCart: true
    }
  },
  {
    name: 'coupons',
    path: '/coupons',
    component: () => import('@/views/Coupons.vue'),
  },
  {
    name: 'shopPage',
    path: '/shop/:shopId/order-type',
    props: true,
    component: () => import('@/views/ShopPage.vue'),
    meta: {
      confirmCartReset: true,
      requiresShop: true
    }
  },
  {
    name: 'categoriesPage',
    path: '/shop/:shopId',
    props: true,
    component: () => import('@/views/CategoriesPage.vue'),
    meta: {
      requiresShop: true,
      showCart: true
    }
  },
  {
    name: 'categoryArticlesPage',
    path: '/shop/:shopId/:categoryName',
    props: true,
    component: () => import('@/views/CategoryArticlesPage.vue'),
    meta: {
      requiresShop: true,
      showCart: true
    }
  },
  {
    name: 'articlePage',
    path: '/shop/:shopId/:categoryName/:articleId/',
    props: true,
    component: () => import('@/views/ArticlePage.vue'),
    meta: {
      requiresShop: true,
      showCart: true,
      disableCart: true,
      showAddItemButton: true
    }
  },
  {
    name: 'articleRedirect',
    path: '/shop/:shopId/redirect/:articleId',
    redirect: (to) => {
      return {
        name: 'articlePage',
        params: {
          shopId: to.params.shopId,
          articleId: to.params.articleId,
          categoryName: to.params.categoryName
        }
      };
    }
  },
  {
    name: 'editOrder',
    path: '/shop/:shopId/:categoryName/:articleId/:orderId',
    props: true,
    component: () => import('@/views/ArticlePage.vue'),
    meta: {
      requiresShop: true,
      showCart: true,
      disableCart: true,
      editMode: true,
      showAddItemButton: true
    }
  },
  {
    name: 'suggestedArticlesPage',
    path: '/shop/:shopId/:categoryName/:articleId/suggested',
    props: true,
    component: () => import('@/views/SuggestedArticlesPage.vue'),
    meta: {
      showCart: true,
      requiresShop: true,
      disableCart: true,
      editMode: true,
      showAddItemButton: true
    }
  },
  {
    name: 'checkoutPage',
    path: '/shop/:shopId/checkout',
    component: () => import('@/views/CheckoutPage.vue'),
    meta: {
      requiresShop: true,
    }
  },
  {
    name: 'paymentPage',
    path: '/shop/:shopId/checkout/payment/:orderId',
    props: true,
    component: () => import('@/views/PaymentPage.vue'),
    meta: {
      requiresShop: true,
    }
  },
  {
    name: 'orderPage',
    path: '/order/:orderId',
    props: true,
    component: () => import('@/views/OrderPage.vue'),
    meta: {
      requiresShop: true,
    }
  },
  {
    name: 'ordersPage',
    path: '/orders',
    component: () => import('@/views/OrdersPage.vue'),
    meta: {
      requiresShop: true,
    }
  },
  {
    name: "termsAndConditions",
    path: '/terms-and-conditions',
    component: () => import('@/views/TermsAndConditions.vue')
  },
  {
    name: "privacyPolicy",
    path: '/privacy-policy',
    component: () => import('@/views/PrivacyPolicy.vue')
  },
  {
    name: "generateQR",
    path: '/generate-qr',
    component: () => import('@/views/GenerateQRCode.vue')
  }

]

const router = createRouter({
  history: createWebHistory(process.env.BASE_URL),
  routes,

})

export default router
