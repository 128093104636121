
export enum CartItemType {
    Article,
    Suggested
}

export enum OrderType {
    TakeAway = "takeAway",
    DineInPlace = "dineInPlace"
}


export enum ScreenType {
    Large = 'large',
    Small = 'small'
}

export enum OrderStatusState {
    CalculatedPrice = 'CalculatedPrice',
    Added = 'Added',
    Started = 'Started',
    Finnished = 'Finnished',
    Done = 'Done',
    Delivered = 'Delivered',
    Rollbacked = 'Rollbacked',
}