import {IonicVue} from '@ionic/vue';
import mitt from 'mitt';
import {createPinia} from 'pinia';
import {createApp} from 'vue';
import App from './App.vue';
import i18n from "./i18n";
import router from './router';
import {messages} from 'i18n-customer-overrides';

/* Core CSS required for Ionic components to work properly */
import '@ionic/vue/css/core.css';

/* Basic CSS for apps built with Ionic */
import '@ionic/vue/css/normalize.css';
import '@ionic/vue/css/structure.css';
/* Theme variables */
import {AppStore} from '@/stores/app';
import {CartStore} from '@/stores/cart';
import {NavigationStore} from '@/stores/navigation';
import {ShopStore} from '@/stores/shop';
import {CouponStore} from "@/stores/coupon";
import './theme/style.scss';
import {OrderType} from "@/enums";
import {isOpen, isReachable} from "@/functions";

// import {registerWorker} from "@/functions/register-worker"; //needed for the future when we want to use push notifications
// registerWorker();
import './registerServiceWorker';
import {AuthStore} from "@/stores/auth";
import {LocationQueryValue} from "vue-router";

const handler = (e:Event)=>{
    const delay = 5000;
    if(navigator.onLine){
        setTimeout(()=>{
            isReachable(window.location.origin).then((online)=>{
                if(!online) {
                    console.log('offline');
                    handler(e);
                } else {
                    //if we are on the offline page, go to home, otherwise do nothing
                    router.currentRoute.value.name==='offline' && router.push({name:'home'});
                }
            });
        },delay);
    } else {
        //regardless of where we are, if we go offline, we go to the offline page
        router.push({name:'offline'});
    }
    console.log(e);
    console.log(navigator.onLine);
};

window.addEventListener("online", handler);
window.addEventListener("offline", handler);

const emitter = mitt();
const pinia = createPinia();

//Add customer specific overrides of i18n texts
i18n.global.mergeLocaleMessage('en', messages.en);
i18n.global.mergeLocaleMessage('sv', messages.sv);
i18n.global.mergeLocaleMessage('fi', messages.fi);
i18n.global.mergeLocaleMessage('ru', messages.ru);
i18n.global.mergeLocaleMessage('no', messages.no);


const app = createApp(App)
    .use(IonicVue)
    .use(router)
    .use(pinia)
    .use(i18n);

router.isReady().then(() => {
    app.config.globalProperties.emitter = emitter;

    app.mount('#app');

    app.config.errorHandler = (err, instance, info) => {
        console.error('Global Error', err, instance, info);
    }
});

const authStore = AuthStore();
const navStore = NavigationStore();
const shopStore = ShopStore();
const cartStore = CartStore();
const appStore = AppStore();
const couponStore = CouponStore();
couponStore.load();

if(authStore.state.isAuthenticated){
    document.body.classList.add('authenticated');
}

router.beforeEach(async (to, from) => {
    console.log('------------------------------------------')
    //we await the loading state so that it is available for each route
    if(authStore.state.loading){
        console.log('authStore.state.loading is true, waiting for it to finish')
        await authStore.waitForLoading()
        console.log('authStore.state.loading is false, continuing')
    }
    
    console.log('routing from', from.name,"to", to.name, "with parameters", to.params, "and query", to.query);
    if(to.meta.requiresAuth && !authStore.state.isAuthenticated){
        if(authStore.state.loading) {
            console.log('auth is loading, should wait for it to finish');
        }
        return { name:'login', query:{ redirectUri: to.fullPath }};
    }

    if(to.query.orderType != undefined || to.query.note != undefined) {
        console.log('route has orderType or note in query, setting them in cart')
        const q = { ...to.query };
        if(to.query.orderType) {
            cartStore.setOrderType(to.query.orderType as OrderType);
            delete q.orderType;
        }
        
        if(to.query.note){
            cartStore.setNote(to.query.note as string);
            delete q.note;
        }
        
        //this shortcircuits the meta stuff which is not ideal
        
        to =  { ...to, query: q};
    }
    

    if(to.params.shopId && shopStore.id != Number(to.params.shopId))
    {
        console.log(`route ${to.name?.toString()} has shopId ${to.params.shopId}, loading shop`);
        //The link contains a shopId, load the shop if it´s not already selected
        await shopStore.load(Number(to.params.shopId));
        if(!shopStore.id) {
            console.log('shop was not set, redirecting to home');
            return { name:'home' };
        }
    }
    
    if(to.meta.requiresShop && shopStore.id && !shopStore.shop?.openForBusiness) {
        console.log('shop is closed, redirecting to home');
        return { name:'home' };
    }
    

    if(to.meta.requiresShop && !shopStore.id){
        console.log(`${to.name?.toString()} requires shop, but none was set, redirecting to home with redirectUri set to ${to.fullPath}`);
        return { name:'home', query: {redirectUri: to.fullPath} };
    }
    
    appStore.state.currentPage = to?.name;
    appStore.state.lastPage = from?.name;
    appStore.state.currentPageFullPath = to?.fullPath;
    appStore.state.lastPageFullPath = from?.fullPath;
    
    cartStore.state.visible = to.meta?.showCart === true;
    console.log(`setting cart.visibility to ${cartStore.state.visible} for route ${to.name?.toString()}`);
    
    // if (to?.name === 'categoriesPage') {
    //     //Support deep linking via QR codes to a specific shop, with a note and a on orderType
    //     const params = new URLSearchParams(window.location.search);
    //     const note = params.get('note');
    //     if (to.params.shopId) {
    //         if (note) {
    //             const shopId = Number(to.params.shopId);
    //             await shopStore.load(shopId);
    //             if (shopStore.shop && !isOpen(shopStore.shop)) {
    //                 return {name: 'home'}
    //             }
    //
    //             cartStore.setNote(note);
    //             params.delete('note')
    //
    //             const orderType = params.get('orderType');
    //             if (orderType) {
    //                 cartStore.setOrderType(orderType as OrderType);
    //                 params.delete('orderType')
    //             }
    //         }
    //     }
    // }
    
    // Hide nav on Home
    if (to.name === 'home' || to?.name==='offline') {
        const classes= [...document.body.classList]
        classes.filter(c=>c.startsWith('shop-')).forEach(c=>document.body.classList.remove(c));
        console.log('removing shop-classes from body', classes);
    } else {
        if (cartStore.state.shopId) {
            document.body.classList.add(`shop-${cartStore.state.shopId ?? 'none'}`);
            console.log('setting shop-class on body to', cartStore.state.shopId);
        }
    }

    if (to.name !== 'home') {
        //If restaurant changed and user has items in cart then clear cart.
        if (cartStore.state.items.length > 0 && shopStore.id && !cartStore.compareShop(shopStore.id)) {
            cartStore.clear(true);
            console.log('clearing cart because shop changed')
        }
    }
    
    if(to.meta.editMode === true){
        cartStore.state.editMode = true;//to.meta.editMode === true;
        console.log('setting editMode to', cartStore.state.editMode, 'for route', to.name?.toString());
    } else {
        cartStore.state.editMode = false;
    }
    
    if(to.meta.showAddItemButton === true) {
        cartStore.state.showAddItemButton = to.meta.showAddItemButton === true;
        console.log('setting showAddItemButton to', cartStore.state.showAddItemButton, 'for route', to.name?.toString());
    } else {
        cartStore.state.showAddItemButton = false;
    }
    

    if(to.meta.disableCart) {
        cartStore.state.disabled = true;
        console.log(`route ${to.name?.toString()} has disabled cart`);
        if (appStore.state.isSmallScreen) {
            cartStore.state.showOrderInfo = false;
            console.log(`route ${to.name?.toString()} hiding order info because of small screen`);
        }
    } else {
        cartStore.state.disabled = false;
    }

    emitter.emit('onNavigate');
})

